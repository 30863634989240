<template>
  <div>
    <van-nav-bar :title="$route.meta.title" left-arrow right-text="主页" @click-left="toContractList" @click-right="toStudent" />
    <div class="detail">
      <div class="item">
        <span class="title">合同编号：</span>
        <span class="value">{{ dataForm.number }}</span>
      </div>
      <div class="item">
        <span class="title">起止时间：</span>
        <span class="value">{{ dataForm.startDate }} ~ {{ dataForm.endDate }}</span>
      </div>
      <div class="item">
        <span class="title">合同金额：</span>
        <span class="value">{{ dataForm.amount | formatMoney }}</span>
      </div>
      <div class="item">
        <span class="title">学习课时：</span>
        <span class="value">{{ dataForm.totalClassHour }} 课时</span>
      </div>
      <div class="item">
        <span class="title">赠送课时：</span>
        <span class="value">{{ dataForm.giftClassHour }} 课时</span>
      </div>
      <div class="item">
        <span class="title">课程内容：</span>
        <span class="value">
          <div class="inner-html">{{ dataForm.courseDescn }}</div>
        </span>
      </div>
      <div class="item">
        <span class="title">合同状态：</span>
        <span class="value">{{ dataForm.contractStatus |formatContractStatus }}</span>
      </div>
      <van-button type="primary" block @click="toContractList">确定</van-button>
    </div>
  </div>
</template>
<script>
import guardianApi from '@/api/guardian'
export default {
  data() {
    return {
      appId: '',
      dataForm: {}
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.dataForm.id = this.$route.params.id
    this.initData()
  },
  mounted() {},
  methods: {
    initData() {
      guardianApi.contractDetail(this.dataForm.id).then(res => {
        if (res.succ) {
          this.dataForm = res.data.item
        }
      })
    },
    toContractList() {
      this.$router.push(`/${this.appId}/guardian/contract/list`)
    },
    toStudent() {
      this.$router.push(`/${this.appId}/guardian`)
    }
  }
}
</script>
<style lang="less" scoped>
.detail {
  padding: 0.3rem;
  background-color: #fff;
  font-size: 0.36rem;
  .item {
    display: flex;
    min-height: 0.9rem;
    line-height: 0.9rem;
    margin-bottom: 0.2rem;
    span.title {
      width: 2rem;
      font-weight: 700;
    }
    span.value {
      flex: 3;
      .inner-html {
        border: 1px solid #ccc;
        padding: 0.1rem 0.2rem;
        line-height: 0.8rem;
        min-height: 1.6rem;
        height: 1.6rem;
        overflow: auto;
        display: block;
      }
    }
  }
}
</style>
